// src/App.js
import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const App = () => {

  const isRequesting = useRef(false)

  useEffect(() => {
    goUrl()
  }, [])

  const goUrl = async () => {
    if (isRequesting.current) {
      return
    }
    isRequesting.current = true

    // 由于手机系统的原因，用户点击短信时，可能点击出来的地址是: 'https://eap.cn/6d200377，如需取消请提前3小时点击https://eap.cn/2a62b713'
    const decodePath = decodeURIComponent(window.location.href)
    // 使用正则表达式匹配 
    // https?:\/\/ 匹配 http:// 或 https://
    let matches = decodePath.match(/https?:\/\/[^\s\/]+(\/(?:[tr]\/)?[a-zA-Z0-9]+)/);
    if (!matches) {
      window.location.href = 'https://www.eap.com.cn'
      return
    }
    const tempUrl = matches[1]; // /t/6d200377 、/r/6d200377 、/6d200377
    if (!tempUrl) {
      window.location.href = 'https://www.eap.com.cn'
      return
    }
    const pathNameArr = tempUrl.split('/')
    let domain = ''
    if (pathNameArr.length == 2) { // 生产
      domain = 'https://api.xinqueyun.com'
    } else if (pathNameArr[1] === 'r') { // 预发布
      domain = 'https://releaseapi.eap.cn'
    } else if (pathNameArr[1] === 't') { // 测试
      domain = 'https://api.eap.cn'
    } else { // 测试
      domain = 'https://api.eap.cn'
    }

    const url = pathNameArr[pathNameArr.length - 1]

    try {
      const response = await fetch(`${domain}/user/web/shortUrl/getRealUrl?url=${url}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("API Response:", data);
      isRequesting.current = false

      if (data && data.code == 0 && data.data && data.data.url) {
        window.location.href = data.data.url
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <Router>
      <Routes>

        {/* 可以添加其他路由 */}
      </Routes>
    </Router>
  );
};

export default App;
